@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SF Pro";
  src: url("../fonts/SFPro/SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro";
  src: url("../fonts/SFPro/SF-Pro-Display-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro";
  src: url("../fonts/SFPro/SF-Pro-Display-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro";
  src: url("../fonts/SFPro/SF-Pro-Display-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
