@use "base";

* {
  box-sizing: border-box;
  font-family: "SF Pro", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-checkbox input[type="checkbox"]:checked:before {
  content: "✔";
  display: block;
  text-align: center;
}

// RANGE INPUT STYLE
.range-slider {
  @apply w-full h-2 bg-black rounded-lg border-none;
}

.range-slider::-webkit-slider-runnable-track {
  @apply invisible;
  background: transparent;
}
.range-slider::-webkit-slider-thumb {
  // @apply w-4 h-4 bg-blue-600 rounded-full cursor-pointer;
  -webkit-appearance: none;
  appearance: none;
}

.range-slider::-moz-range-thumb {
  // @apply w-4 h-4 bg-blue-600 rounded-full cursor-pointer;
}

.range-slider::-ms-thumb {
  // @apply w-4 h-4 bg-blue-600 rounded-full cursor-pointer;
}
// END OF RANGE INPUT STYLE

// COUNTRY SELECT STYLE
button.ReactFlagsSelect-module_selectBtn__19wW7 {
  @apply border border-gray-300 rounded-lg w-full bg-white;
}

/* Custom Scrollbar Styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #c0e2ce;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #35a162;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
